@tailwind base;
@tailwind components;
@tailwind utilities;

.brand-transition {
  @apply transition duration-300 ease-in-out;
}

@keyframes reveal {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}

.reveal {
  animation: reveal 0.5s ease-out;
  overflow: hidden;
  max-height: 1000px;
}
